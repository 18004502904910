import * as React from "react";
import { Helmet } from 'react-helmet';
import '../assets/css/pages/apps-page.css';
import AppsItem from "../components/AppsItem";
import Footer from "../components/Footer";
import Header, { CurrentPageType } from "../components/Header";
import AppsData from '../data/appsData.json';
import ReactGA from 'react-ga';

const AppsPage = () => {
  const renderApps = () => {
    const appsItems = AppsData.apps.map(({id, name, description, hasReadMore, iosLink, androidLink, iconName }) => {
      return (
        <AppsItem key={id} id={id} name={name} description={description} hasReadMore={hasReadMore} iosLink={iosLink} androidLink={androidLink} iconName={iconName} />
      );
    });
    return(
      <React.Fragment>
        {appsItems}
      </React.Fragment>
    )
  }

  React.useEffect(() => {
  	ReactGA.initialize('UA-88504612-1');
  	ReactGA.pageview("/apps");
  }, []);
  
  return (
    <React.Fragment>
      <Helmet>
        <title>Pixel Works Software: Mobile Apps & Game Solvers</title>
        <meta name="description" content="Explore our collection of mobile apps including word game solvers, chess AI, and utility tools for iOS and Android devices." />
        <meta name="keywords" content="mobile apps, word games, scrabble solver, chess AI, game cheats, word finder, iOS apps, Android apps" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.pixelworkssoftware.com/apps" />
        <meta property="og:title" content="Pixel Works Software: Mobile Apps & Game Solvers" />
        <meta property="og:description" content="Explore our collection of mobile apps including word game solvers, chess AI, and utility tools for iOS and Android devices." />
        <meta property="og:image" content="https://www.pixelworkssoftware.com/app-icon.png" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.pixelworkssoftware.com/apps" />
        <meta name="twitter:title" content="Pixel Works Software: Mobile Apps & Game Solvers" />
        <meta name="twitter:description" content="Explore our collection of mobile apps including word game solvers, chess AI, and utility tools for iOS and Android devices." />
        <meta name="twitter:image" content="https://www.pixelworkssoftware.com/app-icon.png" />
        
        {/* Canonical URL */}
        <link rel="canonical" href="https://www.pixelworkssoftware.com/apps" />
      </Helmet>
      <div className="gradient"></div>
      <Header currentPage={CurrentPageType.APPS} />
      <main>
        <h1 className="visually-hidden">Pixel Works Software Mobile Apps</h1>
        {renderApps()}
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default AppsPage;
