import React from 'react';
import { Link } from 'gatsby';
import '../assets/css/components/app-item.css';
import { AppsItemProps } from '../types/appsItem';

const AppsItem: React.FC<AppsItemProps> = ({id, name, description, hasReadMore, iconName, iosLink, androidLink }) => {
    return (
      <div className="app-item">
        <h2 className="app-item__name">{name}</h2>
        <div className="app-item__description-container">
          <img className="app-item__icon" src={require(`../assets/images/${iconName}`).default} alt={`${name} app icon`} width={100} height={100}/>
          <p className="app-item__description">{description}</p>
        </div>
        <div className="app-item__app-store-links">
          {iosLink && (
            <a className="app-item__ios-link" href={iosLink} target="_blank" aria-label={`Download ${name} on the App Store`}>Download on AppStore</a>
          )}
          {androidLink && (
            <a className="app-item__android-link" href={androidLink} target="_blank" aria-label={`Download ${name} on Google Play`}>Download on Google Play</a>
          )}
        </div>
      {hasReadMore && (
         <Link to={`/apps/${id}`} aria-label={`Read More about ${name}`}>Read More</Link>
      )}
      </div>
    );
}

export default AppsItem;
